/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import { useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"

type GLTFResult = GLTF & {
  nodes: {
    Head: THREE.Mesh
    Comb_1: THREE.Mesh
    Comb_2: THREE.Mesh
    Ears: THREE.Mesh
    Body_1: THREE.Mesh
    Body_2: THREE.Mesh
    Arm_R_1: THREE.Mesh
    Arm_R_2: THREE.Mesh
    Screen: THREE.Mesh
    Bottom_Light: THREE.Mesh
    Arm_L_1: THREE.Mesh
    Arm_L_2: THREE.Mesh
    Eyes: THREE.Mesh
    Mouth: THREE.Mesh
  }
  materials: {
    Body: THREE.MeshStandardMaterial
    Comb: THREE.MeshStandardMaterial
    Comb2: THREE.MeshStandardMaterial
    Light: THREE.MeshStandardMaterial
  }
}

const Robby = ( props: JSX.IntrinsicElements["group"] ) => {
  const { nodes, materials } = useGLTF("./models/robot/robot-v1.glb") as GLTFResult

  let screenColor = 0x454545

  const params = {
    color: screenColor,
    transmission: 1,
    opacity: 1,
    metalness: 0,
    roughness: 0,
    ior: 1.5,
    thickness: 0.01,
    specularIntensity: 1,
    specularColor: 0xffffff,
    envMapIntensity: 1,
    lightIntensity: 1,
    exposure: 1
  }

  const screenMaterial = new THREE.MeshPhysicalMaterial( {
    color: params.color,
    metalness: params.metalness,
    roughness: params.roughness,
    ior: params.ior,
    // alphaMap: texture,
    // envMap: hdrEquirect,
    envMapIntensity: params.envMapIntensity,
    transmission: params.transmission, // use material.transmission for glass materials
    specularIntensity: params.specularIntensity,
    opacity: params.opacity,
    side: THREE.DoubleSide,
    transparent: true
  })

  const eyesMaterial = new THREE.MeshStandardMaterial({
    color: '#72dff8',
    emissive: '#72dff8',
  })

  return (
    <group
        position={[ 0, 1, 0 ]}
    >
        <group {...props} dispose={ null }>
          <mesh
            name="Head"
            castShadow
            receiveShadow
            geometry={nodes.Head.geometry}
            material={materials.Body}
            scale={1.63}
          />
          <group name="Comb" position={[0, 0.01, 0]} scale={1.63}>
            <mesh
              name="Comb_1"
              castShadow
              receiveShadow
              geometry={nodes.Comb_1.geometry}
              material={materials.Comb}
            />
            <mesh
              name="Comb_2"
              castShadow
              receiveShadow
              geometry={nodes.Comb_2.geometry}
              material={materials.Comb2}
            />
          </group>
          <mesh
            name="Ears"
            castShadow
            receiveShadow
            geometry={nodes.Ears.geometry}
            material={materials.Comb2}
            position={[0, 0, 1.21]}
            scale={[0.6, 0.6, 0.26]}
          />
          <group name="Body" position={[0, -1.97, 0]}>
            <mesh
              name="Body_1"
              castShadow
              receiveShadow
              geometry={nodes.Body_1.geometry}
              material={materials.Body}
            />
            <mesh
              name="Body_2"
              castShadow
              receiveShadow
              geometry={nodes.Body_2.geometry}
              material={materials.Light}
            />
          </group>
          <group
            name="Arm_R"
            position={[-0.04, -1.29, -0.79]}
            rotation={[-0.97, 0, 0]}
            scale={[0.34, 0.23, 0.23]}
          >
            <mesh
              name="Arm_R_1"
              castShadow
              receiveShadow
              geometry={nodes.Arm_R_1.geometry}
              material={materials.Body}
            />
            <mesh
              name="Arm_R_2"
              castShadow
              receiveShadow
              geometry={nodes.Arm_R_2.geometry}
              material={materials.Light}
            />
          </group>
          <mesh
            name="Screen"
            geometry={nodes.Screen.geometry}
            material={screenMaterial}
            position={[0.04, 0, 0]}
            scale={1.63}
          />
          <mesh
            name="Bottom_Light"
            castShadow
            receiveShadow
            geometry={nodes.Bottom_Light.geometry}
            material={materials.Light}
            position={[0, -2.88, 0]}
            scale={[0.33, 0.12, 0.33]}
          />
          <group
            name="Arm_L"
            position={[-0.07, -1.25, 0.8]}
            rotation={[0.44, -0.9, 2.11]}
            scale={[0.34, 0.23, 0.23]}
          >
            <mesh
              name="Arm_L_1"
              castShadow
              receiveShadow
              geometry={nodes.Arm_L_1.geometry}
              material={materials.Body}
            />
            <mesh
              name="Arm_L_2"
              castShadow
              receiveShadow
              geometry={nodes.Arm_L_2.geometry}
              material={materials.Light}
            />
          </group>
          <mesh
            name="Eyes"
            castShadow
            receiveShadow
            geometry={nodes.Eyes.geometry}
            material={eyesMaterial}
            position={[1.05, 0.17, -0.34]}
            scale={[0.03, 0.16, 0.16]}
          />
          <mesh
            name="Mouth"
            castShadow
            receiveShadow
            geometry={nodes.Mouth.geometry}
            material={eyesMaterial}
            position={[1.05, -0.26, 0]}
            scale={[0.03, 0.07, 0.16]}
          />
        </group>
    </group>
  )
}

export default Robby
useGLTF.preload("./models/robot/robot-v1.glb")