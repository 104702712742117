import { sun, moon } from "./Paths"
import { useEffect } from 'react'
import { interpolate } from "flubber"
import { animate, motion, useTransform, useMotionValue } from "framer-motion"

const paths = [ sun, moon ]

const Nav = ( props: any ) => {
    const i = useMotionValue(0)
    // 0 = sun, 1 = moon
    const path = useTransform(i, [0, 1], paths, { mixer: (a, b) => interpolate(a, b, { maxSegmentLength: 0.05 }) })

    useEffect(() => {
        if (props.darkToggle) {
            i.set(0)
            const animation = animate(i, 1, { duration: 1.0 })
            // console.log(animation)
            return () => animation.stop()
        } else {
            i.set(1)
            const animation = animate(i, 0, { duration: 1.0 })
            return () => animation.stop()
        }
    }, [props.darkToggle])

    return (
        <nav className="px-6 pt-6">
            <ul className="flex gap-4 justify-between font-bold font-Presicav">
                <li>R E N A ( A ) &#40; R &#41; T . D E V(R)</li>
                <li>
                    <div>
                        <svg onClick={ props.setDarkToggle } className="w-6 h-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <motion.path strokeLinecap="round" strokeLinejoin="round" d={ path } />
                        </svg>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default Nav