import { useEffect, Suspense } from "react"
import { Canvas, useThree } from '@react-three/fiber'
import { Stage, Float } from '@react-three/drei'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
// import { Perf } from 'r3f-perf'
import ReactPlayer from 'react-player'

import PortfolioItem from "../components/PortfolioItem"
import Robby from "../components/Robby"
import Loader from "../components/Loader"

const CameraController = () => {
    const { camera, gl } = useThree()
    useEffect(
        () => {
        const controls = new OrbitControls(camera, gl.domElement)

        controls.minDistance = 3
        controls.maxDistance = 20
        return () => {
            controls.dispose()
        }
        },
        [camera, gl]
    )
    return null
}

const Home = () => {
    return <>
        <div className="flex flex-col items-center justify-center">
            <div className="mt-4 border border-solid border-black">
                {/* <video autoPlay controls loop muted className="p-4 md:min-w-full xs:overflow-x-hidden xs:min-h-screen object-cover">
                    <source src="./video/reel.webm" type="video/webm" />
                    <source src="./video/reel.mp4" type="video/mp4" />
                </video> */}
                <ReactPlayer
                    className="p-4 md:min-w-full xs:overflow-x-hidden xs:min-h-screen object-cover"
                    url="https://drvnpublic.s3.eu-central-1.amazonaws.com/hls/reelHLS.m3u8"
                    controls
                    muted
                    autoPlay
                    width="100%"
                    height="100%"
                />
            </div>

            <PortfolioItem title={ "JBL Quantum Event Website" } description={ "Temporary event website that displays a QR code to download movieclips to smartphone." } images={ ["./images/JBL10.jpg", "./images/JBL11.jpg", "./images/JBL12.jpg"] } position={ "left" } year={ "2023" } technology={ "React Front-end, NodeJS Back-end, Hosted on EC2/Vercel, AWS S3 + AWS Lambda, Server-side-events" } />
            <PortfolioItem title={ "JBL Quantum Graphics" } description={ "Concept images for JBL social media and activation campaigns." } images={ ["./images/JBL03.jpg", "./images/JBL02.jpg", "./images/JBL06.jpg", "./images/JBL04.jpg", "./images/JBL01.jpg", "./images/JBL05.jpg"] } position={ "right" } year={ "2023" } technology={ "Photoshop / Illustrator / MidJourney" } />

            <p className="mt-6 text-base text-center">More coming soon!</p>
            <p className="text-base text-center">Be sure to check <a className="hover:underline" href="https://drvn.be" target="_blank">drvn.be</a></p>

            <div className="mt-6 block h-screen w-full border border-solid border-black p-4">
                <Canvas
                    resize={{ scroll: false }}
                    className="bg-bg-dark"
                    shadows dpr={[1, 2]}
                    camera={ {
                        fov: 50,
                        near: 0.1,
                        far: 500,
                        position: [ 3, 1, 3 ]
                    }}
                >
                    {/* <Perf /> */}
                    <Suspense fallback={<Loader />}>
                        <Stage shadows="accumulative" preset="rembrandt" intensity={1}></Stage>
                        {/* <PresentationControls
                            snap
                            global
                            zoom={0.8}
                            rotation={[0, 0, 0]}
                            polar={[0, Math.PI / 4]}
                            azimuth={[-Math.PI / 4, Math.PI / 4]}
                        > */}
                            <CameraController />
                            <Float>
                                <Robby rotation={ [ 0, (-Math.PI / 2.8), 0 ] } />
                            </Float>
                        {/* </PresentationControls> */}
                    </Suspense>
                </Canvas>
            </div>
        </div>
    </>
}

export default Home