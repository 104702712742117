import { useState } from "react"
// import { LazyLoadImage } from "react-lazy-load-image-component"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "popmotion"

type PortfolioItemProps = {
    title: string,
    description: string,
    images: string[],
    position?: string,
    link?: string,
    year: string,
    technology: string
}

const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0
      }
    }
}

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */

const swipeConfidenceThreshold = 10000
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}

const PortfolioItem = ( { title, description, images, link, position, year, technology }: PortfolioItemProps ) => {
    const positionClassImage = position == "left" ? "row-start-1 col-start-1 row-end-4 col-end-3 max-xl:col-start-1 max-xl:row-start-1 max-xl:col-end-1 max-xl:row-end-1 z-0" : "row-start-1 col-start-2 row-end-4 col-end-4 max-xl:col-start-1 max-xl:row-start-1 max-xl:col-end-1 max-xl:row-end-1 z-0"
    const positionClassArrowLeft = position == "left" ? "row-start-2 col-start-1 max-xl:col-start-1 max-xl:row-start-1" : "row-start-2 col-start-2 max-xl:col-start-1 max-xl:row-start-1"
    const positionClassArrowRight = position == "left" ? "row-start-2 col-start-2 max-xl:col-start-1 max-xl:row-start-1" : "row-start-2 col-start-3 max-xl:col-start-1 max-xl:row-start-1"
    const marginClass = position == "left" ? "ml-6 max-xl:ml-0 text-left max-xl:text-center" : "mr-6 max-xl:mr-0 text-right max-xl:text-center"
    const [[page, direction], setPage] = useState([0, 0])

    const imageIndex = wrap(0, images.length, page)

    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    }

    return (
        <div className="grid grid-cols-3 max-xl:grid-cols-1 grid-rows-3 max-xl:grid-rows-1 mt-6 overflow-x-hidden border border-solid border-black p-4">
            <AnimatePresence initial={false} custom={direction}>
                <motion.img
                    className={ `${positionClassImage}` }
                    key={page}
                    src={images[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 }
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(_, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                        paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                        paginate(-1);
                        }
                    }}
                />
            </AnimatePresence>
            <div className={ `bg-opacity-60 cursor-pointer select-none self-center z-10 ${ positionClassArrowLeft } font-bold flex justify-center items-center bg-bg-light w-8 h-8 rounded-full ml-6` } onClick={() => paginate(1)}>
                {"<"}
            </div>
            <div className={ `bg-opacity-60 cursor-pointer select-none self-center justify-self-end z-10 ${ positionClassArrowRight } font-bold flex justify-center items-center bg-bg-light w-8 h-8 rounded-full mr-6` } onClick={() => paginate(-1)}>
                {">"}
            </div>
            <div className={`flex flex-col ${marginClass}`}>
                <p className="text-2xl mt-6"><span className="font-bold">{ title }</span>&nbsp;<span>{ year }</span></p>
                <p className="text-base mt-6">{ description }</p>
                <p className="text-sm mt-6">{ technology }</p>
                <p>{ link ? link : "" }</p>
            </div>
        </div>
    )
}

export default PortfolioItem