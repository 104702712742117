import { useState } from 'react'
import Wrapper from './components/Wrapper'

const App = () => {
  const [darkToggle, setDarkToggle] = useState(false)

  const toggleDarkMode = () => {
    setDarkToggle(!darkToggle)
  }

  return (
    <>
      <div className={`${ darkToggle && 'dark' }`}>
          <Wrapper darkToggle={ darkToggle } setDarkToggle={ toggleDarkMode } />
      </div>
    </>
  )
}

export default App