import Home from '../pages/Home.tsx'
import Nav from './Nav.tsx'
import Footer from './Footer.tsx'

import { motion } from "framer-motion"

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

const Wrapper = ( props: any ) => {
    return (
        <>
            <motion.div
                animate={{
                    // this is possible because of the custom plugin in tailwind.config.js
                    // all color values are written to the global css variables
                    backgroundColor: props.darkToggle === true ? "var(--bg-dark)" : "var(--bg-light)",
                    color: props.darkToggle === true ? "var(--text-light" : "var(--text-dark)"
                }}
                transition={{ duration: 1.0 }}
                className="min-h-screen w-full mx-auto bg-bg-light text-text-dark dark:text-text-light dark:bg-bg-dark"
            >
                <Nav darkToggle={ props.darkToggle } setDarkToggle={ props.setDarkToggle }/>
                <div className="px-6">
                    <Router>
                        <Routes>
                            <Route path='/' element={<Home />} />
                        </Routes>
                    </Router>
                </div>
                <Footer />
            </motion.div>
        </>
    )
}

export default Wrapper