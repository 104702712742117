const Footer = () => {
    return (
        <footer className="p-1 mt-4 w-full sticky top-[100vh] bg-bg-dark dark:bg-bg-light">
            <div className="flex justify-center">
                <p className="text-center text-xs text-text-medium dark:text-text-dark">&copy; 2022-{new Date().getFullYear()} - Website By RENART.DEV - Made With <a className="hover:underline" href="https://react.dev">React</a> - Hosted On <a className="hover:underline" href="https://vercel.com">Vercel</a></p>
            </div>
        </footer>
    )
}

export default Footer